body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital@0;1&display=swap");
#map {
  height: 180px;
}
code {
  font-family: "Montserrat", sans-serif, source-code-pro, Menlo, Monaco,
    Consolas, "Courier New", monospace;
}
.main-stop {
  stop-color: #d14dcd;
}
.alt-stop {
  stop-color: #ed9d99;
}
.black {
  fill: #bdbdbd;
}
.gradient-bg {
  fill: url(#gradient) #fff;
}
